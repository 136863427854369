var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"6b481258bf24bab9214242b2f49f7441e612bda5"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const ENVIRONMENT =
  process.env.ENVIRONMENT || process.env.NEXT_PUBLIC_ENVIRONMENT;

function filterOutLCP(event) {
  if (event.sdk && event.sdk.name === "LCP") {
    return null;
  }
  return event;
}
Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://d5a304cffd9f4f6182b392f4130f1d34@o1395385.ingest.sentry.io/6726298",
  integrations: [Sentry.replayIntegration()],
  environment: ENVIRONMENT || "local",
  ignoreTransactions: [
    "TypeError: window\\[\\w+\\]\\.init is not a function\\. \\(In 'window\\[\\w+\\]\\.init\\(\\{argument:t,auto:!0\\}\\)', 'window\\[\\w+\\]\\.init' is undefined\\)",
  ],
  tracesSampleRate: 0.01,
  replaysOnErrorSampleRate: 0.01,
  beforeSend: (event) => filterOutLCP(event),
});
